
import { computed, defineComponent } from 'vue'
import Page from '@/components/Page.vue'
import Button from '@/components/Form/Button.vue'
import DataTable, { Column } from '@/components/DataTable/DataTable.vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { IAccount, IUserPro } from '@/types'
import { ObjPairString } from '@/types/general'
import PageTop from '@/components/PageTop.vue'
import Edit from '@/components/DataTable/Edit.vue'

export default defineComponent({
  components: {
    Page,
    Button,
    DataTable,
    PageTop,
    Edit,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { isAdmin } = store.getters

    const accounts = computed(
      () => (store.getters.accounts as IAccount[]).reduce<ObjPairString>(
        (carry, account) => {
          carry[account.id] = account.name
          return carry
        }, {},
      ),
    )
    const onEditUserClick = (userID: string) => {
      router.push({ name: 'User', params: { userID } })
    }

    return {
      onEditUserClick,
      isAdmin,
      columns: computed<Column[]>(() => [
        {
          key: 'fullName',
          title: 'Navn',
          canSort: true,
        },
        ...!route.params.accountID ? [{
          key: 'account',
          title: 'Konto',
          canSort: true,
        }] : [],
        {
          key: 'email',
          title: 'Email',
          canSort: true,
        },
        {
          key: 'isActive',
          title: 'Status',
          canSort: true,
        },
        {
          key: 'isExpired',
          title: 'Udløbet',
          canSort: true,
        },
        {
          key: 'edit',
          title: '',
          hide: !isAdmin,
        },
      ]),
      accounts: computed<IAccount[]>(() => store.getters.accounts),
      rows: computed<IUserPro[]>(() => {
        const { accountID } = route.params
        const users = accountID
          ? store.getters.usersByAccount(accountID)
          : store.getters.proUsers
        const now = new Date().getTime()

        return users.map((user: IUserPro) => {
          let end = NaN
          if (user.expiryDate && user.isActive) {
            end = new Date(user.expiryDate).getTime()
          }

          const isExpired = end && now > end

          return {
            fullName: user.fullName,
            email: user.email,
            isActive: user.isActive,
            isExpired,
            id: user.id,
            account: accounts.value[user.accountID],
          }
        })
      }),
      account: computed<IAccount | undefined>(() => {
        const { accountID } = route.params
        return accountID && store.getters.getAccountByID(accountID)
      }),
    }
  },
})

